import { Result } from "antd";

export interface PageCodeProps {
  title: string;
  message: string;
}

export const Result404 = ({ title, message }: PageCodeProps) => (
  <Result className="result404" status={404} title={title} subTitle={message} />
);
