import { InfoCircleFilled, LinkOutlined } from '@ant-design/icons';
import explorer from './RSExplorer.png';

export interface BoxProps {
    openTitle: string,
    openMessage: string,
    openExtra: string,
    openUrl: string,
    aboutTitle: string,
    aboutMessage: string,
    aboutUrl: string,
};

export const Box = (Props: BoxProps) => (
    <div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            maxWidth: "1200px",
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "flex-start"}}>
                <a href={Props.openUrl} target="_self">
            <div style={{padding: "1rem", maxWidth: "580px", backgroundColor: "#8cd3ef", marginTop: "2.5rem", height: "200px", boxShadow: "0px 8px 20px 4px rgba(0,0,0,0.3)"}}>
            
                <div style={{display: "grid", gridTemplateColumns: "170px 1fr", color: "#000"}}>
                    
                    <div><img src={explorer} /></div>
                    <div>
                        <h3 style={{color: "#333", fontSize: "24px", fontFamily:"Avenir Demi"}} >{Props.openTitle}</h3>
                        <p style={{fontSize: "16px"}}>{Props.openMessage}</p>
                    </div>
                    
                </div>
                <div style={{textAlign: "right", color: "#fff"}}>
                    <p>{Props.openExtra}</p>

                </div>
            </div></a>
            <a href={Props.aboutUrl} target="_self" >
            <div style={{padding: "1rem", maxWidth: "580px", backgroundColor: "#8cd3ef", marginTop: "2.5rem", display: "grid", gridTemplateColumns: "170px 1fr", height: "200px", boxShadow: "0px 8px 20px 4px rgba(0,0,0,0.3)"}}>
                
                <div style={{paddingLeft: "10px", paddingTop: "10px"}}><InfoCircleFilled style={{fontSize: 130, color: "#fff"}} /></div>
                <div>
                    <h3 style={{color: "#333", fontSize: "24px", fontFamily:"Avenir Demi"}}>{Props.aboutTitle}</h3>
                    <p style={{color: "#000", fontSize: "16px"}}>{Props.aboutMessage}</p>
                </div>
            </div></a>
        </div>        
    </div>
)