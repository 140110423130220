export interface MessageProps {
    message: string
};

export const Message = (Props: MessageProps) => (
    <div style={{backgroundColor: "#65C6E9", padding: "2.0rem" }}>
        <div style={{maxWidth: "1200px", marginLeft: "auto", marginRight: "auto", textAlign: "center"}}>
            <h2 style={{fontSize: "30px", color: "#ffffff", fontFamily:"Avenir Demi"}}>{Props.message}</h2>
        </div>        
    </div>
)