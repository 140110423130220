import useWindowSize from "../Hooks/useWindowsSize";

interface FooterProps {
    legal: string,
    slogan: string
};

export const Footer = (Props: FooterProps) => {
  const { width } = useWindowSize();

  if (width != undefined) {
    // Mobile
    if (width < 768) {
      return (
        <footer style={{ padding: "2.5rem" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              maxWidth: "1200px",
              marginLeft: "auto",
              marginRight: "auto",
              alignItems: "flex-start",
            }}
          >
            <div style={{ maxWidth: "470px  ", fontSize: "12px", order: 2 }}>
              {Props.legal}
            </div>
            <div
              style={{
                display: "flex",
                order: 1,
                color: "#65b33a",
                fontSize: "20px",
                marginBottom: "23px"
              }}
            >
              {Props.slogan}
            </div>
          </div>
        </footer>
      );
    }

    // Tablet
    if (width < 992 && width > 768) {
      return (
        <footer style={{ padding: "2.5rem" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              maxWidth: "1200px",
              marginLeft: "auto",
              marginRight: "auto",
              alignItems: "flex-start",
            }}
          >
            <div style={{ maxWidth: "470px  ", fontSize: "12px", order: 1 }}>
                {Props.legal}
            </div>
            <div
              style={{
                display: "flex",
                order: 2,
                color: "#65b33a",
                fontSize: "20px",
              }}
            >
              {Props.slogan}
            </div>
          </div>
        </footer>
      );
    }
  }

  // Desktop
  return (
    <footer style={{ padding: "2.5rem" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
          maxWidth: "1200px",
          marginLeft: "auto",
          marginRight: "auto",
          alignItems: "flex-start",
        }}
      >
        <div style={{ maxWidth: "470px  ", fontSize: "12px", order: 1 }}>
            {Props.legal}
        </div>
        <div
          style={{
            display: "flex",
            order: 2,
            color: "#65b33a",
            fontSize: "20px",
          }}
        >
          {Props.slogan}
        </div>
      </div>
    </footer>
  );
};
