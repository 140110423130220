import { PageHeader } from 'antd';
import {ReactComponent as LogoIcon} from './DNVlogo.svg';

interface HeaderProps {
    titleText: string
};

export const Header = (Props: HeaderProps) => (
    <PageHeader 
        title={<LogoIcon />}
        extra={<h2 style={{fontFamily: "DNV Display Regular", fontSize: "24px"}}>{Props.titleText}</h2>}
    />
);