import { Button } from 'antd';
import { FacebookFilled, TwitterCircleFilled, LinkedinFilled, RightOutlined } from '@ant-design/icons';
// import useWindowSize from "../Hooks/useWindowsSize";
// import { useEffect, useState } from 'react';

interface DNVFooterProps {
    facebookUrl: string,
    twitterUrl: string,
    linkedinUrl: string,
    aboutTitle: string, 
    aboutItemTxt1: string, 
    aboutItemUrl1: string,
    aboutItemTxt2: string,
    aboutItemUrl2: string,
    aboutItemTxt3: string,
    aboutItemUrl3: string,
    aboutItemTxt4: string,
    aboutItemUrl4: string,
    contactTitle: string,
    contactItemTxt1: string,
    contactItemUrl1: string,
    contactItemTxt2: string,
    contactItemUrl2: string,
    contactItemTxt3: string,
    contactItemUrl3: string,
    contactItemTxt4: string,
    contactItemUrl4: string,
    legalTxt1: string,
    legalUrl1: string,
    legalTxt2: string,
    legalUrl2: string,
    legalTxt3: string,
    legalUrl3: string,
    legalTxt4: string,
    legalUrl4: string,
};

// interface ICalculatedWindowSize {
//     mobile: boolean,
//     tablet: boolean,
//     desktop: boolean
// }

export const DNVFooter = (Props: DNVFooterProps) => {
    // const { width } = useWindowSize();

    // const [device, setDevice] = useState<ICalculatedWindowSize>({
    //     mobile: false,
    //     tablet: false,
    //     desktop: true
    // });

    // useEffect(() => {
    //     if (width != undefined) {
    //         // Mobile
    //         if (width < 768) {
    //             setDevice({
    //                 mobile: true,
    //                 tablet: false,
    //                 desktop: false
    //             })
    //         }
    
    //         // Tablet
    //         if (width < 1177 && width > 768) {
    //             setDevice({
    //                 mobile: false,
    //                 tablet: true,
    //                 desktop: false
    //             })
    //         }
    
    //         if (width >= 1177) {
    //             setDevice({
    //                 mobile: false,
    //                 tablet: false,
    //                 desktop: true
    //             })
    //         }
    //     }       

    // }, [width]);

    return (
    <div style={{backgroundColor: "#0f204b", width: "100%"}}>
       <div style={{maxWidth: "1200px", marginLeft: "auto", marginRight: "auto", color: "#ffffff", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", alignItems: "flex-start", padding: "2rem"}}>
            
            <div style={{display: "flex", flexDirection: "column", marginTop: "1rem", marginBottom: "1rem", order: 1 }}>
                <div style={{textAlign: "center"}}>
                    <h4 style={{fontSize: "13px", color: "#8cd3ef"}}>FOLLOW US ON SOCIAL MEDIA <hr style={{width: "50px", border: "1px solid #8cd3ef"}} /></h4>
                    <ul style={{display: "flex", flexDirection: "row", listStyle: "none", justifyContent: "space-between", alignItems: "flex-start", marginTop: "20px"}}>
                        <li style={{marginRight: "14px"}}>
                            <Button href={Props.facebookUrl} type="primary" shape="circle" size="large" icon={<FacebookFilled  />} style={{backgroundColor:"#009fda"}} />
                        </li>
                        <li style={{marginRight: "14px"}}>
                            <Button href={Props.twitterUrl} type="primary" shape="circle" size="large" icon={<TwitterCircleFilled  />} style={{backgroundColor:"#009fda"}} />
                        </li>
                        <li>
                            <Button href={Props.linkedinUrl} type="primary" shape="circle" size="large" icon={<LinkedinFilled  />} style={{backgroundColor:"#009fda"}} />
                        </li>
                    </ul>
                </div>
            </div>            
            
            <div style={{display: "flex", flexDirection: "column", marginTop: "1rem", marginBottom: "1rem", order: 2}}>
                <div>
                    <h4 style={{fontSize: "13px", color: "#8cd3ef", borderBottom: "2px solid #8cd3ef", width: "70px", paddingBottom: "10px"}}>{Props.aboutTitle}</h4>
                    <ul>
                        <li className="arrow"><a href={Props.aboutItemUrl1}>{Props.aboutItemTxt1}</a></li>
                        <li className="arrow"><a href={Props.aboutItemUrl2}>{Props.aboutItemTxt2}</a></li>
                        <li className="arrow"><a href={Props.aboutItemUrl3}>{Props.aboutItemTxt3}</a></li>
                        <li className="arrow"><a href={Props.aboutItemUrl4}>{Props.aboutItemTxt4}</a></li>
                    </ul>
                </div>                
            </div>
            
            <div style={{display: "flex", flexDirection: "column", marginTop: "1rem", marginBottom: "1rem", order: 3 }}>
                <div>
                    <h4 style={{fontSize: "13px", color: "#8cd3ef", borderBottom: "2px solid #8cd3ef", width: "70px", paddingBottom: "10px" }}>{Props.contactTitle}</h4>
                    <ul>
                        <li className="arrow"><a href={Props.contactItemUrl1}>{Props.contactItemTxt1}</a></li>
                        <li className="arrow"><a href={Props.contactItemUrl2}>{Props.contactItemTxt2}</a></li>
                        <li className="arrow"><a href={Props.contactItemUrl3}>{Props.contactItemTxt3}</a></li>
                        <li className="arrow"><a href={Props.contactItemUrl4}>{Props.contactItemTxt4}</a></li>
                    </ul>
                </div>
            </div>
            
            <div style={{display: "flex", flexDirection: "column", marginTop: "1rem", marginBottom: "1rem", order: 4 }}>
                <div>
                    <h4 style={{fontSize: "13px", color: "#8cd3ef", borderBottom: "2px solid #8cd3ef", width: "70px", marginTop: "28px"}}></h4>
                    <ul>
                        <li style={{color: "#8cd3ef", fontSize: "12px"}}><a href={Props.legalUrl1}>{Props.legalTxt1}</a></li>
                        <li style={{color: "#8cd3ef", fontSize: "12px"}}><a href={Props.legalUrl2}>{Props.legalTxt2}</a></li>
                        <li style={{color: "#8cd3ef", fontSize: "12px"}}><a href={Props.legalUrl3}>{Props.legalTxt3}</a></li>
                        <li style={{color: "#8cd3ef", fontSize: "12px"}}><a href={Props.legalUrl4}>{Props.legalTxt4}</a></li>
                    </ul>
                </div>                
            </div>
       </div>
    </div>
    )
}