import { useState, useEffect } from 'react';

interface IData {
    applicationTitle: string,
    pageMessage: string,
    openExplorerTitle: string, 
    openExplorerMessage: string, 
    openExplorerExtra: string, 
    openExplorerUrl: string,
    aboutExplorerTitle: string, 
    aboutExplorerMessage: string, 
    aboutExplorerUrl: string,
    statusTitle: string,
    statusMessage: string,
    contactText: string,
    contactMail: string,
    dnvFooterFacebook: string,
    dnvFooterTwitter: string,
    dnvFooterLinkedin: string,
    dnvFooterAboutTitle: string, 
    dnvFooterAboutItemTxt1: string, 
    dnvFooterAboutItemUrl1: string,
    dnvFooterAboutItemTxt2: string,
    dnvFooterAboutItemUrl2: string,
    dnvFooterAboutItemTxt3: string,
    dnvFooterAboutItemUrl3: string,
    dnvFooterAboutItemTxt4: string,
    dnvFooterAboutItemUrl4: string,
    dnvFooterContactTitle: string,
    dnvFooterContactItemTxt1: string,
    dnvFooterContactItemUrl1: string,
    dnvFooterContactItemTxt2: string,
    dnvFooterContactItemUrl2: string,
    dnvFooterContactItemTxt3: string,
    dnvFooterContactItemUrl3: string,
    dnvFooterContactItemTxt4: string,
    dnvFooterContactItemUrl4: string,
    dnvFooterLegalTxt1: string,
    dnvFooterLegalUrl1: string,
    dnvFooterLegalTxt2: string,
    dnvFooterLegalUrl2: string,
    dnvFooterLegalTxt3: string,
    dnvFooterLegalUrl3: string,
    dnvFooterLegalTxt4: string,
    dnvFooterLegalUrl4: string,
    footerSlogan: string,
    footerLegal: string
}

const useData = () => {
    const [isLoading, setIsLoading] = useState(false);
    
    const [data, setData] = useState<IData>({
        applicationTitle: "",
        pageMessage: "",
        openExplorerTitle: "", 
        openExplorerMessage: "", 
        openExplorerExtra: "", 
        openExplorerUrl: "",
        aboutExplorerTitle: "", 
        aboutExplorerMessage: "", 
        aboutExplorerUrl: "",
        statusTitle: "",
        statusMessage: "",
        contactText: "",
        contactMail: "",
        dnvFooterFacebook: "",
        dnvFooterTwitter: "",
        dnvFooterLinkedin: "",
        dnvFooterAboutTitle: "", 
        dnvFooterAboutItemTxt1: "", 
        dnvFooterAboutItemUrl1: "",
        dnvFooterAboutItemTxt2: "",
        dnvFooterAboutItemUrl2: "",
        dnvFooterAboutItemTxt3: "",
        dnvFooterAboutItemUrl3: "",
        dnvFooterAboutItemTxt4: "",
        dnvFooterAboutItemUrl4: "",
        dnvFooterContactTitle: "",
        dnvFooterContactItemTxt1: "",
        dnvFooterContactItemUrl1: "",
        dnvFooterContactItemTxt2: "",
        dnvFooterContactItemUrl2: "",
        dnvFooterContactItemTxt3: "",
        dnvFooterContactItemUrl3: "",
        dnvFooterContactItemTxt4: "",
        dnvFooterContactItemUrl4: "",
        dnvFooterLegalTxt1: "",
        dnvFooterLegalUrl1: "",
        dnvFooterLegalTxt2: "",
        dnvFooterLegalUrl2: "",
        dnvFooterLegalTxt3: "",
        dnvFooterLegalUrl3: "",
        dnvFooterLegalTxt4: "",
        dnvFooterLegalUrl4: "",
        footerSlogan: "",
        footerLegal: ""
    });

    useEffect(() => {
        let isMounted = true;

        const fetchData = () => {
            setIsLoading(true);

            if (isMounted) {

            try {
                fetch("./data.json").then(
                    function(result) {
                        return result.json();
                    }
                ).then(function(data: IData){
                    if(isMounted)
                    setData(data);
                }).catch(
                    function(error){
                        console.log("fetching data: ", error);
                    }
                )

            } catch (err: any) {

            } finally {
                isMounted && setIsLoading(false);
            }
        }
            
        }

        
        fetchData();

        const cleanUp = () => {
            isMounted = false;
        }

        return cleanUp;


    }, [])

    return data;
}

export default useData