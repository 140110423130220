import React, { useEffect } from "react";
import "./App.css";

import { Header } from "./Features/Header";
import { BackTop } from "antd";
import { Footer } from "./Features/Footer";
import { Result404 } from "./Features/404";
import { Contact } from "./Features/Contact";
import { DNVFooter } from "./Features/DNVFooter";
import { Message } from "./Features/Message";
import { Box } from "./Features/Box";

import useData from "./Hooks/useData";

function App() {
  const {
    applicationTitle,
    pageMessage,
    openExplorerTitle,
    openExplorerMessage,
    openExplorerExtra,
    openExplorerUrl,
    aboutExplorerTitle,
    aboutExplorerMessage,
    aboutExplorerUrl,
    statusTitle,
    statusMessage,
    contactMail,
    contactText,
    dnvFooterFacebook,
    dnvFooterTwitter,
    dnvFooterLinkedin,
    dnvFooterAboutTitle,
    dnvFooterAboutItemTxt1,
    dnvFooterAboutItemUrl1,
    dnvFooterAboutItemTxt2,
    dnvFooterAboutItemUrl2,
    dnvFooterAboutItemTxt3,
    dnvFooterAboutItemUrl3,
    dnvFooterAboutItemTxt4,
    dnvFooterAboutItemUrl4,
    dnvFooterContactTitle,
    dnvFooterContactItemTxt1,
    dnvFooterContactItemUrl1,
    dnvFooterContactItemTxt2,
    dnvFooterContactItemUrl2,
    dnvFooterContactItemTxt3,
    dnvFooterContactItemUrl3,
    dnvFooterContactItemTxt4,
    dnvFooterContactItemUrl4,
    dnvFooterLegalTxt1,
    dnvFooterLegalUrl1,
    dnvFooterLegalTxt2,
    dnvFooterLegalUrl2,
    dnvFooterLegalTxt3,
    dnvFooterLegalUrl3,
    dnvFooterLegalTxt4,
    dnvFooterLegalUrl4,
    footerLegal,
    footerSlogan,
  } = useData();

  useEffect(() => {
    document.title = "rules.dnv.com - Rules and Standards - DNV";
  }, []);

  return (
    <div>
      <Header titleText={applicationTitle} />
      <BackTop />
      <Message message={pageMessage} />
      <Box
        openTitle={openExplorerTitle}
        openMessage={openExplorerMessage}
        openExtra={openExplorerExtra}
        openUrl={openExplorerUrl}
        aboutTitle={aboutExplorerTitle}
        aboutMessage={aboutExplorerMessage}
        aboutUrl={aboutExplorerUrl}
      />
      <Result404 title={statusTitle} message={statusMessage} />
      {/* <Contact mail={contactMail} text={contactText} /> */}
      <DNVFooter
        facebookUrl={dnvFooterFacebook}
        twitterUrl={dnvFooterTwitter}
        linkedinUrl={dnvFooterLinkedin}
        aboutTitle={	dnvFooterAboutTitle}
        aboutItemTxt1={	dnvFooterAboutItemTxt1}
        aboutItemUrl1={	dnvFooterAboutItemUrl1}
        aboutItemTxt2={	dnvFooterAboutItemTxt2}
        aboutItemUrl2={	dnvFooterAboutItemUrl2}
        aboutItemTxt3={	dnvFooterAboutItemTxt3}
        aboutItemUrl3={	dnvFooterAboutItemUrl3}
        aboutItemTxt4={	dnvFooterAboutItemTxt4}
        aboutItemUrl4={	dnvFooterAboutItemUrl4}
        contactTitle={	dnvFooterContactTitle}
        contactItemTxt1={	dnvFooterContactItemTxt1}
        contactItemUrl1={	dnvFooterContactItemUrl1}
        contactItemTxt2={	dnvFooterContactItemTxt2}
        contactItemUrl2={	dnvFooterContactItemUrl2}
        contactItemTxt3={	dnvFooterContactItemTxt3}
        contactItemUrl3={	dnvFooterContactItemUrl3}
        contactItemTxt4={	dnvFooterContactItemTxt4}
        contactItemUrl4={	dnvFooterContactItemUrl4}
        legalTxt1={	dnvFooterLegalTxt1}
        legalUrl1={	dnvFooterLegalUrl1}
        legalTxt2={	dnvFooterLegalTxt2}
        legalUrl2={	dnvFooterLegalUrl2}
        legalTxt3={	dnvFooterLegalTxt3}
        legalUrl3={	dnvFooterLegalUrl3}
        legalTxt4={	dnvFooterLegalTxt4}
        legalUrl4={	dnvFooterLegalUrl4}
      />
      <Footer legal={footerLegal} slogan={footerSlogan} />
    </div>
  );
}

export default App;
